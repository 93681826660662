import Vue from 'vue'
import Router from 'vue-router'


Vue.use(Router);

export default new Router({
    mode: 'history',
    routes: [
        {
            path: '/',
            name: 'home',
            meta: {
                layout: 'main',
                auth: true
            },
            component: () => import('./pages/home.vue'),
        },
        {
            path: '/articles',
            name: 'articles',
            meta: {
                auth: true,
                title: 'Articles'
            },
            component: () => import('./pages/news.vue'),
        },
        {
            path: '/contact',
            name: 'contact',
            meta: {
                auth: true,
                title: 'Contact'
            },
            component: () => import('./pages/contact.vue'),
        },
        {
            path: '/article/:url',
            name: 'article',
            meta: {
                auth: true,
                title: 'Article'
            },
            props: true,
            component: () => import('./pages/news-details.vue')
        },
        {
            path: '/advisory',
            name: 'advisory',
            meta: {
                auth: true,
                title: 'Advisory'
            },
            component: () => import('./pages/advisory.vue'),
        },
        {
            path: '/appointment',
            name: 'appointment',
            meta: {
                auth: true,
                title: 'Appointment'
            },
            component: () => import('./pages/appointment.vue'),
        },
        {
            path: '/services',
            name: 'services',
            meta: {
                auth: true,
                title: 'Services'
            },
            component: () => import('./pages/services.vue'),
        },
        {
            path: '/terms-of-service',
            name: 'terms-of-service',
            meta: {
                auth: true,
                title: 'Terms of Service'
            },
            component: () => import('./pages/terms-of-service.vue'),
        },
        {
            path: '/privacy-policy',
            name: 'privacy-policy',
            meta: {
                auth: true,
                title: 'Privacy Policy'
            },
            component: () => import('./pages/privacy-policy.vue'),
        },
        {
            path: '/newsletter',
            name: 'newsletter',
            meta: {
                auth: true,
                title: 'Stay in Touch'
            },
            component: () => import('./pages/newsletter.vue'),
        },
        {   path: '*',
            component: () => import('./pages/home.vue')
        }
    ]
})
