<template>
  <!-- header begin -->
  <header>
    <div class="top-bar d-none d-md-block">
      <div class="container">
        <div class="row d-flex align-items-center">
          <div class="col-xl-6 offset-xl-1 col-lg-8 col-md-7">
            <div class="header-info">
              <span v-if="contact.phone"><i class="fas fa-phone"></i> {{contact.phone}}</span>
              <span v-if="contact.email"><i class="fas fa-envelope"></i> {{contact.email}}</span>
            </div>
          </div>
          <div class="col-xl-5 col-lg-4 col-md-4">
            <div class="header-top-right-btn f-right">
              <router-link to="/appointment" class="btn">Make Appointment</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- menu-area -->
    <div class="header-menu-area">
      <div class="container">
        <div class="row">
          <div class="col-xl-4 col-lg-4 col-md-8 col-8 d-flex align-items-center">
            <div class="logo logo-circle pos-rel">
              <router-link to="/">
                <img src="../assets/img/logo/logo-1.png" alt="">
              </router-link>
            </div>
          </div>
          <div class="col-xl-8 col-lg-8 col-md-4 col-4">
            <div class="header-right f-right">
              <div class="header-social-icons f-right d-none d-xl-block">
                <ul>
                  <li v-if="contact.facebook"><a :href="contact.facebook" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                  <li v-if="contact.instagram"><a :href="contact.instagram" target="_blank"><i class="fab fa-instagram"></i></a></li>
                  <li v-if="contact.youtube"><a :href="contact.youtube" target="_blank"><i class="fab fa-youtube"></i></a></li>
                  <li v-if="contact.linkedin"><a :href="contact.linkedin" target="_blank"><i class="fab fa-linkedin"></i></a></li>
                  <li v-if="contact.twitter"><a :href="contact.twitter" target="_blank"><i class="fab fa-twitter"></i></a></li>
                </ul>
              </div>
            </div>
            <div class="header__menu f-right" @click="menuStatus = false">
              <nav id="mobile-menu" class="menu" :class="{'show': menuStatus}">
                <ul>
                  <li>
                    <router-link to="/">Home</router-link>
                  </li>
                  <li>
                    <router-link to="/services">Services</router-link>
                  </li>
                  <li>
                    <router-link :to="{ path: '/articles', query: query({offset: 0})}">News</router-link>
                  </li>
                  <li>
                    <router-link to="/contact">Contact</router-link>
                  </li>
                  <li>
                    <router-link to="/advisory">Advisory Board</router-link>
                  </li>
<!--                  <li><a href="blog.html">Packages</a></li>-->
                </ul>
              </nav>
            </div>
            <div class="menu-btn-mobile" @click="menuStatus = true">
              <a href="#nav" class="meanmenu-reveal" ><span></span><span></span><span></span></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
  <!-- header end -->
</template>

<script>
    export default {
        data() {
            return {
               menuStatus: false
            }
        },
        methods: {
            query(newQuery) {
                return {
                    ...this.$route.query,
                    ...newQuery
                }
            },
        },
        name: "ht-nav-bar",
        props: ['contact']
    }
</script>

<style scoped>
  .menu-btn-mobile {
    display: none;
  }
  .menu-btn-mobile a {
    border: 1px solid #E12454;
    color: #E12454;
    cursor: pointer;
    display: block;
    font-family: Arial,Helvetica,sans-serif;
    font-size: 1px;
    font-weight: 700;
    height: 40px;
    line-height: 37px;
    padding: 0 9px;
    width: 40px;
    margin: 23px 0;
  }
  .menu-btn-mobile a span {
    background: #E12454;
    display: block;
    height: 3px;
    margin-top: 5px;
    position: relative;
    top: 6px;
  }
  @media (max-width: 1200px) {
    .logo-circle::before {
      display: none;
    }
  }
  @media (max-width: 992px) {
   .menu-btn-mobile {
     display: flex;
     justify-content: flex-end;
   }
    #mobile-menu {
      position: fixed;
      z-index: 99;
      top: 0;
      right: 0;
      transform: translateX(100%);
      visibility: hidden;
      transition: .3s;
      width: 100%;
      display: flex;
      justify-content: flex-end;
    }
    #mobile-menu.show {
      visibility: visible;
      transform: translateX(0);
    }
    #mobile-menu ul {
      display: flex;
      height: 100vh;
      background: #0c1923;
      width: 300px;
      flex-direction: column;
      padding-top: 100px;
    }
    #mobile-menu ul li {
      margin-left: 0;
    }
    #mobile-menu ul a {
      padding: 17px 20px;
      color: #fff;
      border-top: 1px solid rgba(255, 255, 255, 0.5);
    }
    #mobile-menu ul li:last-child a {
      border-bottom: 1px solid rgba(255, 255, 255, 0.5);
    }
  }
</style>
