<template>
  <footer>
    <div class="footer-top primary-bg pt-65 pb-10">
      <div class="container">
        <div class="row">
          <div class="col-xl-5 col-lg-6 col-md-8 col-md-12 col-sm-12">
            <div class="footer-contact-info mb-30">
              <div class="footer-logo">
                <router-link to="/">
                  <img src="../assets/img/logo.png" alt="">
                </router-link>
              </div>
              <div class="footer-emailing">
                <ul>
                  <li>
                    <a class="email-to" :href="`mailto:${contact.email}`">
                      <i class="far fa-envelope"></i>{{contact.email}}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-xl-2 offset-xl-1 col-lg-3 col-md-4 col-sm-6 col-xs-6">
            <div class="footer-widget mb-30">
              <div class="footer-title">
                <h3>Legal</h3>
              </div>
              <div class="footer-menu">
                <ul>
                  <li>
                    <router-link to="/privacy-policy">Privacy Policy</router-link>
                  </li>
                  <li>
                    <router-link to="/terms-of-service">Terms of Service</router-link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-xl-2 offset-xl-1 col-lg-3 col-md-4 col-sm-6 col-xs-6">
            <div class="footer-widget mb-30">
              <div class="footer-title">
                <h3>Menu</h3>
              </div>
              <div class="footer-menu">
                <ul>
                  <li>
                    <router-link to="/">Home</router-link>
                  </li>
                  <li>
                    <router-link to="/services">Services</router-link>
                  </li>
                  <li>
                    <router-link :to="{ path: '/articles', query: query({offset: 0})}">News</router-link>
                  </li>
                  <li>
                    <router-link to="/contact">Contact</router-link>
                  </li>
                  <li>
                    <router-link to="/advisory">Advisory Board</router-link>
                  </li>
                   <li>
                    <router-link to="/newsletter">Newsletter</router-link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-bottom pt-25 pb-20">
      <div class="container">
        <div class="row">
          <div class="col-xl-12">
            <div class="footer-copyright text-center">
              <p class="white-color">
                Copyright @{{new Date().getFullYear()}} Health Travel Secure,
                Powered by <a href="https://www.flexiblesites.com/" target="_blank">FlexibleSites</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
    export default {
        name: "ht-footer",
        props: ['contact'],
        methods: {
            query(newQuery) {
                return {
                    ...this.$route.query,
                    ...newQuery
                }
            },
        }
    }
</script>

<style scoped>
  footer {
    /*margin-top: 150px;*/
  }
  .footer-logo img {
    width: 100px;
  }
  .footer-emailing {
    padding-left: 15px;
  }
  @media (min-width: 768px) and (max-width: 1200px){
    .footer-contact-info {
      padding: 30px 13px;
    }
  }
  @media (max-width: 500px) {
    .footer-contact-info {
      flex-direction: column;
      padding: 15px 6px;
    }
    .footer-logo {
      margin-bottom: 15px;
    }
    .footer-emailing {
      padding-left: 0;
    }
  }
  .footer-contact-info {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  .email-to {
    color: inherit;
  }
</style>
