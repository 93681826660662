import Vue from 'vue'
import App from './App.vue'
import router from './router';
import axios from "axios";
import VueAxios from "vue-axios";
import VueMoment from 'vue-moment';
import AOS from 'aos';
import VModal from 'vue-js-modal';
import 'aos/dist/aos.css';
import Meta from 'vue-meta';
import VueSocialSharing from 'vue-social-sharing';
import Vuelidate from 'vuelidate';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

const http = axios.create({
    baseURL: 'https://flexiblesites.net/healthtravelsecure/'
});

Vue.config.productionTip = false;

window.axios = require('axios');
Vue.use(VueAxios, http, axios);
Vue.use(VueMoment);
Vue.use(VModal);
Vue.use(Meta);
Vue.use(VueSocialSharing);
Vue.use(Vuelidate);
Vue.use(VueSweetalert2);

new Vue({
  created () {
    AOS.init()
  },
  router,
  render: h => h(App),
}).$mount('#app');
