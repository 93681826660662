<template>
  <div class="email-popup-wrapper" v-if="register">
    <div class="email-modal-content">
      <div class="close-btn" @click="closeModal">
        <i class="fa fa-times"></i>
      </div>
      <h3>Register</h3>
      <p class="mt-3">
        Register with us to stay up to date on promotions and news.  We will not spam you or share your information.
      </p>

      <form class="form user-form"
            @submit.prevent="submitForm"
            action="https://formspree.io/f/xayarblq"
            method="POST">
        <div class="row">
          <div class="col-lg-12">
            <label class="input-group">
              <input class="form__field" type="email" name="email" :class="{ 'is-invalid': submitted && $v.user.email.$error }" v-model="user.email" placeholder="E-mail *"/>
              <div v-if="submitted && $v.user.email.$error" class="invalid-feedback">
                <span v-if="!$v.user.email.required">Email is required</span>
                <span v-if="!$v.user.email.email">Email is invalid</span>
              </div>
            </label>
            <div class="input-group_left">
              <label class="form__checkbox-label mb-2"><span class="form__label-text">I'm Not A Robot</span>
                <input class="form__input-checkbox" autocomplete="off" type="checkbox" name="intervention-and-Prevention" v-model="user.not_A_Robot"/><span class="form__checkbox-mask"></span>
              </label>
            </div>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-12">
            <button class="form__submit" type="submit">Submit	</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import {required, email} from "vuelidate/lib/validators";
export default {
  name: "email-popup",
  data() {
    return {
      user: {
        email: "",
        not_A_Robot: false
      },
      submitted: false,
      register: false,
    }
  },
  validations: {
    user: {
      email: {required, email},
    }
  },
  created() {
    if (!localStorage.getItem("register")) {
      this.register = true;
    }
  },
  methods: {
    closeModal() {
      this.register = false;
      localStorage.setItem("register", String(true));
    },
    submitForm(ev) {

      this.submitted = true;

      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      if (this.user.not_A_Robot) {
        this.$swal.fire({
          icon: 'error',
          title: 'Please try again',
          showConfirmButton: false,
          timer: 2000
        });
        return;
      }

      ev.preventDefault();
      const form = ev.target;
      const data = new FormData(form);
      const xhr = new XMLHttpRequest();
      xhr.open(form.method, form.action);
      xhr.setRequestHeader("Accept", "application/json");
      xhr.onreadystatechange = () => {
        if (xhr.readyState !== XMLHttpRequest.DONE) return;
        if (xhr.status === 200) {
          form.reset();
          this.$swal.fire({
            icon: 'success',
            title: 'Thank you',
            showConfirmButton: false,
            timer: 1500
          });
          this.closeModal();
        } else {
          let res = JSON.parse(xhr.response);
          this.$swal.fire({
            icon: 'error',
            title: res.error ? res.error : 'Please try again',
            showConfirmButton: false,
            timer: 2000
          });
        }
      };
      xhr.send(data);
    }
  }
}
</script>

<style scoped>
.email-popup-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #00000063;
  z-index: 99999;
  display: flex;
  align-items: center;
  justify-content: center;
}
.email-modal-content {
  width: 500px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 8px 16px 0px rgb(0 0 0 / 20%);
  border-top: 4px solid #8fb569;
  padding: 30px;
  position: relative;
}
.email-modal-content .close-btn {
  position: absolute;
  top: -16px;
  right: 30px;
  background: #8fb569;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  cursor: pointer;
  transition: .3s;
}
.email-modal-content .close-btn:hover {
  background: #18a346;
}
.email-popup-wrapper input {
  width: 100%;
  border-radius: 0;
  border: 1px solid #ccc;
  padding: 10px 15px;
}
.email-popup-wrapper input.is-invalid {
  border-color: #dc3545;
}
.email-popup-wrapper button {
  background: #18a346;
  color: #fff;
  padding: 5px 25px;
  border: none;
  font-size: 16px;
  transition: .3s;
}
.email-popup-wrapper button:hover {
  background: #8fb569;
}
.invalid-feedback {
  display: block;
}
.input-group_left {
  position: fixed;
  top: -10000px;
  left: -10000px;
  opacity: 0;
}
@media (max-width: 530px) {
  .email-modal-content {
    width: 100%;
  }
}
</style>
