<template>
  <div id="app" :class="{'overflow': loader}">
    <div class="loader-wrapper" v-if="loader">
      <img src="./assets/loading-animacion.gif" alt="">
    </div>
    <htNavBar :contact="contact_data" v-if="contact_data && contact_data.length !== 0"/>
    <router-view></router-view>
    <htFooter :contact="contact_data" v-if="contact_data && contact_data.length !== 0" />


    <div class="cookie-acceptance" v-if="cookieAcceptance">
      <div class="cookie-acceptance-text">
        This website uses cookies to ensure you get the best experience on our website. See our
        <router-link to="/privacy-policy">Privacy Policy</router-link> and <router-link to="/terms-of-service">Terms of Service</router-link>.
      </div>
      <button class="cookie-acceptance-btn" @click="closeCookieAcceptance()">Accept</button>
    </div>

    <email-popup />
  </div>
</template>

<script>
import htNavBar from './components/ht-nav-bar';
import htFooter from './components/ht-footer'
import api from "./services/api";
import EmailPopup from "./components/email-popup";

export default {
    name: 'App',
    data() {
      return {
        contact_data: [],
        loader: true,
        cookieAcceptance: false,
      }
    },
    async created() {
        window.scrollTo(0, 0);
        this.loader = true;
        if (!localStorage.getItem("cookieconsent")) {
            this.cookieAcceptance = true;
        }
        this.contact_data = await api.getContact();
        if (this.contact_data) {
            this.contact_data = this.contact_data[0];
            this.loader = false;
        } else {
            this.contact_data = [];
            this.loader = false;
        }
    },
    methods: {
        closeCookieAcceptance() {
            this.cookieAcceptance = false;
            localStorage.setItem("cookieconsent", String(true));
        }
    },
    components: {
      EmailPopup,
      htNavBar,
      htFooter
    },
    watch: {
        '$route' (to) {
          if(to.matched[0].path == "/article/:url") {
            document.title = 'Health Travel Secure' + (to.params.title ? ' - ' + to.params.title : '') || 'Health Travel Secure'
          } else {
            document.title = 'Health Travel Secure' + (to.meta.title ? ' - ' + to.meta.title : '') || 'Health Travel Secure'
          }
        }
    }
}
</script>

<style>
  @import "assets/css/bootstrap.min.css";
  @import "assets/css/animate.min.css";
  @import "assets/css/nice-select.css";
  @import "assets/css/magnific-popup.css";
  @import "assets/css/all.min.css";
  @import "assets/css/meanmenu.css";
  @import "assets/css/slick.css";
  @import "assets/css/default.css";
  @import "assets/css/style.css";
  @import "assets/css/responsive.css";

  #mc_embed_signup div.mce_inline_error {
    background-color: transparent !important;
    color: red !important;
    font-weight: normal !important;
  }

  .overflow {
    overflow: hidden;
    height: 100vh;
  }

  .cookie-acceptance {
    position: fixed;
    z-index: 99999;
    width: 600px;
    bottom: 0;
    left: 0;
    padding: 30px;
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    border-top: 4px solid #8fb569;
  }

  .cookie-acceptance .cookie-acceptance-text {
    font-size: 18px;
  }

  .cookie-acceptance .cookie-acceptance-text a {
    color: #24476a;
    transition: .3s;
    text-decoration: underline;
  }

  .cookie-acceptance .cookie-acceptance-text a:hover {
    color: #8fb569;
  }

  .cookie-acceptance button {
    width: 100%;
    padding: 10px;
    margin-top: 10px;
    background: #35679a;
    border: none;
    color: #fff;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 500;
    transition: .3s;
    letter-spacing: 1px;
  }

  .cookie-acceptance button:hover {
    opacity: 0.8;
  }

  .loader-wrapper {
    position: fixed;
    z-index: 999999999;
    background-color: #fff;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .close-modal {
    display: none;
  }
  .close-modal {
    border: 1px solid #ccc;
    background: transparent;
    width: 40px;
    height: 40px;
    margin: 10px;
    border-radius: 50%;
    color: #444;

  }

  .input-tel {
    height: 70px !important;
    min-height: 70px !important;
  }
  .input-tel input, .country-selector, .country-selector input {
    height: 100% !important;
    box-shadow: none !important;
    border-radius: 0 !important;
  }
  .country-selector__country-flag {
    top: 33px !important;
  }
  .country-selector.has-value .country-selector__input {
    padding-top: 8px !important;
  }

  @media (max-width: 600px) {
    .cookie-acceptance {
      width: 100%;
    }
    .vm--modal {
      width: 100% !important;
      left: 50% !important;
      transform: translateX(-50%);
    }
    .close-modal {
      display: block;
    }
  }
</style>
