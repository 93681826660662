import Vue from "vue";
import getToken from "./getToken";

let token = '';

async function getNewToken() {
    if (localStorage.getItem('accessToken')) {
        token = localStorage.getItem('accessToken');
    } else {
        token = await getToken.token();
        localStorage.setItem('accessToken', token);
    }
}

async function setToken() {
    token = await getToken.token();
    localStorage.setItem('accessToken', token);
    location.reload();
}

export default {

    getContact: async () => {
        await getNewToken();
        return Vue.axios
            .get(`items/contact?status=published`,
                {
                    headers: {
                        "Authorization": `Bearer ${token}`
                    }
                })
            .then(res => res.data.data)
            .catch(async () => {
                await setToken();
                return null
            });
    },
    getFiles: async (id) => {
        await getNewToken();
        return Vue.axios
            .get(`/files/${id}?status=published`,
                {
                    headers: {
                        "Authorization": `Bearer ${token}`
                    }
                })
            .then(res => res.data)
            .catch(async () => {
                await setToken();
                return null
            });
    },
    about: async () => {
        await getNewToken();
        return Vue.axios
            .get(`items/about?status=published&fields=id,description,help_description,help_title,image,status,title`,
                {
                    headers: {
                        "Authorization": `Bearer ${token}`
                    }
                })
            .then(res => res.data.data)
            .catch(async () => {
                await setToken();
                return null
            });
    },
    headerCarousel: async () => {
        await getNewToken();
        return Vue.axios
            .get(`items/header_carousel?status=published&fields=*.*`,
                {
                    headers: {
                        "Authorization": `Bearer ${token}`
                    }
                })
            .then(res => res.data.data)
            .catch(async () => {
                await setToken();
                return null
            });
    },
    services: async (limit = null) => {
        await getNewToken();
        return Vue.axios
            .get(`items/services?status=published&${limit ? 'limit=' + limit + '&' : ''}`,
                {
                    headers: {
                        "Authorization": `Bearer ${token}`
                    }
                })
            .then(res => res.data.data)
            .catch(async () => {
                await setToken();
                return null
            });
    },
    newsHomePage: async () => {
        await getNewToken();
        return Vue.axios
            .get(`items/news?status=published&sort=-date&fields=*.*`,
                {
                    headers: {
                        "Authorization": `Bearer ${token}`
                    }
                })
            .then(res => res.data.data)
            .catch(async () => {
                await setToken();
                return null
            });
    },
    news: async (offset = 0, limit = 4) => {
        await getNewToken();
        return Vue.axios
            .get(`items/news?sort=-date&status=published&offset=${offset}&limit=${limit}`,
                {
                    headers: {
                        "Authorization": `Bearer ${token}`
                    }
                })
            .then(res => res.data.data)
            .catch(async () => {
                await setToken();
                return null
            });
    },
    newsDetail: async (url) => {
        await getNewToken();
        return Vue.axios
            .get(`items/news?filter[slug]=${url}&fields=*.*`,
                {
                    headers: {
                        "Authorization": `Bearer ${token}`
                    }
                })
            .then(res => res.data.data)
            .catch(async () => {
                await setToken();
                return null
            });
    },
    search: async (text) => {
        await getNewToken();
        return Vue.axios
            .get(`items/news?filter[title][like]=${text}&filter[description][logical]=or&filter[description][like]=${text}&filter[text][logical]=or&filter[text][like]=${text}&fields=*.*`,
                {
                    headers: {
                        "Authorization": `Bearer ${token}`
                    }
                })
            .then(res => res.data)
            .catch(async () => {
                await setToken();
                return null
            });
    },
    getAdvisors: async () => {
        await getNewToken();
        return Vue.axios
            .get(`items/advisors?status=published`,
                {
                    headers: {
                        "Authorization": `Bearer ${token}`
                    }
                })
            .then(res => res.data.data)
            .catch(async () => {
                await setToken();
                return null
            });
    },
    serviceFeatures: async (id = null) => {
        await getNewToken();
        return Vue.axios
            .get(`items/service_features?filter[service][eq]=${id}&status=published&sort=sort`,
                {
                    headers: {
                        "Authorization": `Bearer ${token}`
                    }
                })
            .then(res => res.data.data)
            .catch(async () => {
                await setToken();
                return null
            });
    },
    appointment: async (data) => {
        await getNewToken();
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${token}`);
        myHeaders.append("Content-Type", "application/json");
        var raw = JSON.stringify(data);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        return fetch("https://flexiblesites.net/healthtravelsecure/items/appointment", requestOptions)
    },
    helpFeatures: async () => {
        await getNewToken();
        return Vue.axios
            .get(`items/help_features?status=published&sort=sort`,
                {
                    headers: {
                        "Authorization": `Bearer ${token}`
                    }
                })
            .then(res => res.data.data)
            .catch(async () => {
                await setToken();
                return null
            });
    },
}
